/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.__3gt_MM2w .M_NXYzV0 {
  font-family: var(--font-family);
  font-weight: bold;
  color: #17243e;
  text-align: center;
}
@media (min-width: 1200px) {
  .__3gt_MM2w {
    padding: 3rem 0;
    background-color: var(--primary-color-white);
  }
  .__3gt_MM2w .M_NXYzV0 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .bRZ7qQbK {
    display: flex;
    flex-wrap: wrap;
    width: 75rem;
    margin: 0 auto;
  }
  .ixKlquJ5 {
    background-color: #F8FAFD;
    margin-right: 1rem;
    margin-bottom: 16px;
    padding: 1rem;
    width: calc(50% - 0.5rem);
    border: 1px solid var(--primary-more-light-grey);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    transition: all 0.3s;
  }
  .ixKlquJ5:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  .ixKlquJ5:nth-of-type(2n) {
    margin-right: 0;
  }
  .ixKlquJ5 .tcONB_d6 {
    margin-bottom: 10px;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--primary-color-black);
  }
  .ixKlquJ5 .oGDgp_Ij {
    font-size: 36px;
    color: var(--primary-color-blue);
  }
  .ixKlquJ5 .ubS_AFlX {
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--primary-color-grey);
    line-height: 22px;
  }
}
@media (max-width: 1199px) and (min-width: 0px) {
  .__3gt_MM2w {
    padding: 1rem;
  }
  .__3gt_MM2w .ant-collapse-borderless {
    background-color: var(--primary-color-white) !important;
  }
  .__3gt_MM2w .ant-collapse > .ant-collapse-item {
    margin-bottom: 10px;
  }
  .__3gt_MM2w .ant-collapse > .ant-collapse-item:last-child {
    margin-bottom: 0;
  }
  .__3gt_MM2w .M_NXYzV0 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .__3gt_MM2w .tcONB_d6 {
    font-size: 14px;
    font-weight: bold;
    color: #17243e;
    font-family: var(--font-family);
    background-color: var(--primary-color-white);
    margin-bottom: 8px;
    border: 1px solid #EEF3FA;
    padding: 0.875rem 1rem;
    width: 100%;
  }
  .__3gt_MM2w .tcONB_d6:last-child {
    margin-bottom: 0;
  }
  .__3gt_MM2w .ubS_AFlX {
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--primary-grey-text);
    line-height: 22px;
    padding: 0.75rem;
    background-color: #F8F8FA;
    border: 1px solid #EEF3FA;
  }
}

